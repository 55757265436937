import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import TimeAgo from 'react-timeago';

const MAX_POLLS = 15;
const POLL_INTERVAL = 5000;
const API_BASE_URL = 'https://speedtester.poc-speedo.btcsp.co.uk';

const states = {
    READY: 'READY',
    RUNNING_TEST: 'RUNNING_TEST',
    TIMED_OUT: 'TIMED_OUT',
    TEST_COMPLETE: 'TEST_COMPLETE',
    API_ERROR: 'API_ERROR'
};

const DEFAULT_RESULTS = {
    up: null,
    down: null,
    completed: null
};

let attempts = 0;

function Conditional({ condition, children }) {
    return (condition) ? children : null;
}

function ResultMetric({ speed, thresholds }) {
    function rating(speed) {
        if (speed <= thresholds.poor) {
            return "red";
        } else if (speed <= thresholds.medium) {
            return "orange";
        } else {
            return "green";
        }
    }

    return <div
        style={{
            backgroundColor: rating(speed)
        }}
        className="ResultMetric"
    >
        <span className="ResultMetric-speed">{speed}</span>
        <span>Mbps</span>
    </div>;
}

function poll(testId, handlers) {
    console.log(testId);
    setTimeout(() => {
        fetch(`${API_BASE_URL}`, { headers: { "testId": testId } })
            .then(response => response.json())
            .then(json => {
                console.log({
                    event: 'ApiPollSuccess',
                    response: json
                });

                if (json.results) {
                    handlers.setResults(json.results);
                    handlers.setState(states.TEST_COMPLETE);
                }
                else {
                    ++attempts;

                    if (attempts > MAX_POLLS) {
                        handlers.setState(states.TIMED_OUT);
                        return;
                    }

                    poll(testId, handlers);
                }
            })
            .catch(error => {
                console.error({
                    event: 'ApiPollError',
                    error
                });

                handlers.setState(states.API_ERROR);
                handlers.setError(error);
            });
    }, POLL_INTERVAL);
}

function SpeedTest() {
    const [ currentState, setState ] = useState(states.READY);
    const [ identifier, setIdentifier ] = useState('');
    const [ results, setResults ] = useState(DEFAULT_RESULTS);
    const [ error, setError ] = useState(null);
    
    function startTest() {
        attempts = 0;
        setState(states.RUNNING_TEST);
        setResults(DEFAULT_RESULTS);

        fetch(
            `${API_BASE_URL}?deviceId=${identifier}`, 
            {
                mode: "cors",
                method: "POST"
            }
        )
        .then(response => response.json())
        .then(json =>  poll(json.testId, { setState, setResults }))
        .catch(error => {
            setState(states.API_ERROR);
            setError(error);
        });
    }

    useEffect(() => { 
        console.log({ 
            identifier,
            currentState,
            error,
            results
        });
    });

    return <div>
        <div className="TestCard">
            <header>
                <h1>Start a speed test</h1>
            </header>
            <form onSubmit={(event) => {
                event.preventDefault();
                startTest();
            }}>
            <label htmlFor="identifier">Identifier</label>
                <input
                    name="identifier"
                    type="text"
                    value={identifier}
                    disabled={currentState === states.RUNNING_TEST}
                    onChange={(event) => setIdentifier(event.target.value)}
                />
                <div className="buttons">
                    <button 
                        type="submit"
                        disabled={identifier.length === 0 || currentState === states.RUNNING_TEST}>
                        Run test
                    </button>
                </div>
            </form>
        </div>
        <Spinner isLoading={currentState === states.RUNNING_TEST} />
        <Conditional condition={currentState === states.API_ERROR}>
            <div className="ResultsCard">
                <h1 style={{ fontSize: '3em' }}>
                    <span role="img" aria-label="Embarassed">😳</span>
                </h1>
                <p style={{ color: '#aaa' }}>
                    API call failed.
                </p>
            </div>
        </Conditional>
        <Conditional condition={currentState === states.TIMED_OUT}>
            <div className="ResultsCard">
                <h1 style={{ fontSize: '3em' }}>
                    <span role="img" aria-label="Disappointed">😞</span>
                </h1>
                <p style={{ color: '#aaa' }}>
                    No result after {MAX_POLLS} checks. We gave up.
                </p>
            </div>
        </Conditional>
        <Conditional condition={currentState === states.TEST_COMPLETE}>
            <div className="ResultsCard">
                <ResultMetric 
                    speed={results.up}
                    thresholds={{
                        poor: 5,
                        medium: 10
                    }} />
                <ResultMetric 
                    speed={results.down}
                    thresholds={{
                        poor: 25,
                        medium: 35
                    }} />
                <p style={{ color: '#888' }}>Test completed <TimeAgo date={results.completed} />.</p>
            </div>
        </Conditional>
    </div>;
};

export default SpeedTest;